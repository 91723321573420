import * as React from "react";

const VideoCamOffIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15" fill="none">
    <path d="M3.31818 11.6626H2.54545C1.69545 11.6626 1 11.1307 1 10.4807V3.38983C1 2.73983 1.69545 2.20801 2.54545 2.20801H11.8182C12.6682 2.20801 13.3636 2.73983 13.3636 3.38983V3.98074" stroke="#5F6A77" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.6137 4.27539C17.8455 4.39357 18 4.57086 18 4.80722V9.06174C18 9.47538 17.4591 9.77083 16.9955 9.65265L13.3637 8.70721V10.4799C13.3637 11.1299 12.6682 11.6618 11.8182 11.6618H7.95459" stroke="#5F6A77" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 7C5.55228 7 6 6.55228 6 6C6 5.44772 5.55228 5 5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7Z" stroke="#5F6A77" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 1L1 14" stroke="#5F6A77" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default VideoCamOffIcon;
