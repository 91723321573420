import * as React from "react";

const ScreenShareOnIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
        <path d="M4.29337 1.5H11.7C14.0734 1.5 14.6667 2.1675 14.6667 4.83V9.5775C14.6667 12.2475 14.0734 12.9075 11.7067 12.9075H4.29337C1.92671 12.915 1.33337 12.2475 1.33337 9.585V4.83C1.33337 2.1675 1.92671 1.5 4.29337 1.5Z" stroke="#5F6A77" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 12.915V17.0001" stroke="#5F6A77" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2 11L14 11" stroke="#5F6A77" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 17H12" stroke="#5F6A77" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

export default ScreenShareOnIcon;