import * as React from "react";

const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="gray"
  >
    <path
      d="M6.43555 7.69336H19.4808"
      stroke="black"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.43555 12.042H16.2195"
      stroke="black"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.9164 10.9549V5.51933C24.9164 3.12769 22.9596 1.1709 20.568 1.1709H5.34843C2.9568 1.1709 1 3.12769 1 5.51933V21.3911C1 22.2608 1.65227 22.9131 2.52195 22.9131C2.9568 22.9131 3.28293 22.8043 3.60906 22.4782L6.21812 19.8692C7.08781 18.9995 8.17492 18.5646 9.26202 18.5646H11.8711H20.568C22.9596 18.5646 24.9164 16.6078 24.9164 14.2162V10.9549Z"
      stroke="black"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ChatIcon;
