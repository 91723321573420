import React from "react";

function SettingsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M22.6405 14.6364C22.4082 14.4182 22.2921 14.091 22.2921 13.7637C22.2921 13.5455 22.2921 13.2182 22.2921 13C22.2921 12.7818 22.2921 12.4546 22.2921 12.2364C22.2921 11.9091 22.4082 11.5818 22.6405 11.3636L24.6151 9.50914C24.9636 9.18187 25.0797 8.63634 24.8474 8.19997L23.4536 5.90909C23.2213 5.47273 22.6405 5.25455 22.1759 5.36364L19.5045 6.12735C19.156 6.23644 18.8076 6.12727 18.4591 5.90909C17.9945 5.58182 17.5299 5.36369 16.9491 5.14551C16.6007 5.03642 16.3684 4.70906 16.2522 4.38179L15.5553 1.87278C15.4392 1.43642 14.9746 1 14.3938 1H11.6062C11.0254 1 10.5608 1.32733 10.4447 1.87278L9.74777 4.38179C9.63162 4.70906 9.39932 4.92733 9.05086 5.14551C8.47011 5.36369 8.0055 5.69091 7.5409 5.90909C7.19245 6.12727 6.84399 6.12735 6.49554 6.12735L3.82406 5.36364C3.35946 5.25455 2.7787 5.47273 2.5464 5.90909L1.15259 8.19997C0.920286 8.63634 0.920287 9.18187 1.38489 9.50914L3.35946 11.3636C3.59176 11.5818 3.70791 11.9091 3.70791 12.2364C3.70791 12.4546 3.70791 12.7818 3.70791 13C3.70791 13.2182 3.70791 13.5455 3.70791 13.7637C3.70791 14.091 3.59176 14.4182 3.35946 14.6364L1.38489 16.491C1.03644 16.8183 0.920286 17.3637 1.15259 17.8L2.5464 20.0909C2.7787 20.5273 3.35946 20.7455 3.82406 20.6364L6.49554 19.8728C6.84399 19.7637 7.19245 19.8727 7.5409 20.0909C8.0055 20.4182 8.47011 20.6364 9.05086 20.8546C9.39932 20.9637 9.63162 21.2909 9.74777 21.6182L10.4447 24.1274C10.5608 24.5637 11.0254 25 11.6062 25H14.3938C14.9746 25 15.4392 24.6728 15.5553 24.1274L16.2522 21.6182C16.3684 21.2909 16.6007 21.0728 16.9491 20.8546C17.5299 20.6364 17.9945 20.3091 18.4591 20.0909C18.8076 19.8727 19.156 19.8728 19.5045 19.8728L22.1759 20.6364C22.6405 20.7455 23.2213 20.5273 23.4536 20.0909L24.8474 17.8C25.0797 17.3637 25.0797 16.8183 24.6151 16.491L22.6405 14.6364Z" stroke="#5F6A77" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 16.1636C14.9245 16.1636 16.4846 14.6984 16.4846 12.8909C16.4846 11.0834 14.9245 9.61816 13 9.61816C11.0756 9.61816 9.5155 11.0834 9.5155 12.8909C9.5155 14.6984 11.0756 16.1636 13 16.1636Z" stroke="#5F6A77" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default SettingsIcon;